export const techTags = [
  {
    id: 1,
    name: "Front-End",
  },
  {
    id: 2,
    name: "Back-End",
  },
  {
    id: 3,
    name: "DevOps",
  },
  {
    id: 4,
    name: "Maintenance",
  },
]

export const aboutSections = [
  {
    id: 1,
    heading: "Industry Vertical",
    description: "RealTech, Real Estate, Commercial Property Management",
  },
  {
    id: 2,
    heading: "Business Model",
    description: "B2B SaaS",
  },
  {
    id: 3,
    heading: "Our Work",
    description: "Custom Software Development, DevOps",
  },
]

export const techImages = [
  {
    id: 1,
    icon: "/caseStudy/strada/tech1.svg",
  },
  {
    id: 2,
    icon: "/caseStudy/strada/tech2.svg",
  },
  {
    id: 3,
    icon: "/caseStudy/strada/tech3.svg",
  },
  {
    id: 4,
    icon: "/caseStudy/strada/tech4.svg",
  },
  {
    id: 5,
    icon: "/caseStudy/strada/tech5.svg",
  },
  {
    id: 6,
    icon: "/caseStudy/strada/tech6.svg",
  },
  {
    id: 7,
    icon: "/caseStudy/strada/tech7.svg",
  },
  {
    id: 8,
    icon: "/caseStudy/strada/tech8.svg",
  },
  {
    id: 9,
    icon: "/caseStudy/strada/tech9.svg",
  },
  {
    id: 10,
    icon: "/caseStudy/strada/tech10.svg",
  },
  {
    id: 11,
    icon: "/caseStudy/strada/tech11.svg",
  },
  {
    id: 12,
    icon: "/caseStudy/strada/tech12.svg",
  },
  {
    id: 13,
    icon: "/caseStudy/strada/tech13.svg",
  },
]

export const aboutProjectIcons = [
  { id: 1, icon: "/caseStudy/strada/about1.svg" },
  { id: 2, icon: "/caseStudy/strada/about2.svg" },
  { id: 3, icon: "/caseStudy/strada/about3.svg" },
]

export const keyChallenges = [
  {
    id: 1,
    title: "Legacy Issues",
    para: "Lack of documentation on previous development and outdated practices",
  },
  {
    id: 2,
    title: "End-to-End Development Responsibilities",
    para: "Our team took on the responsibility of end-to-end development while another design team was already onboard",
  },
  {
    id: 3,
    title: "Client Request for Improvements",
    para: "Client requested improvement of existing features and implementation of new ones",
  },
  {
    id: 4,
    title: "Development Plan for Meeting Client Needs",
    para: "The team had to familiarise themselves with the concept and challenges of the project. The team had to then identify and address the needs of the project, creating a plan that would improve the tool",
  },
]

export const solutions = [
  {
    id: 1,
    heading: "Updated Code",
    description:
      "The legacy code was updated to use modern frameworks like ReactJS and Django while enhancing the existing system.",
    icon: "/caseStudy/strada/solution1.svg",
  },
  {
    id: 2,
    heading: "More Efficiency",
    description:
      "Numerous bugs in the existing code were fixed while new features like RFPs and checklists were added.",
    icon: "/caseStudy/strada/solution2.svg",
  },
  {
    id: 3,
    heading: "Auto-nesting",
    description:
      "The Budget Calendar listing was improved by implementing Auto-nesting, which allows new events and tasks to be added as needed.",
    icon: "/caseStudy/strada/solution3.svg",
  },
]

export const featuresList = [
  {
    id: 1,
    feature: "Checklist",
    icon: "/caseStudy/strada/feature1.svg",
    icon2: "/caseStudy/strada/feature11.svg",
    image: "/caseStudy/strada/featurebg1.png",
    paragraph:
      "Provides a list of tasks and activities for efficient organization and management of assets.",
  },
  {
    id: 2,
    feature: "Return For Proposals",
    icon: "/caseStudy/strada/feature2.svg",
    icon2: "/caseStudy/strada/feature12.svg",
    image: "/caseStudy/strada/featurebg2.png",
    paragraph:
      "Enables efficient tracking and management of Requests for Proposal.",
  },
  {
    id: 3,
    feature: "Budget Calendar",
    icon: "/caseStudy/strada/feature3.svg",
    icon2: "/caseStudy/strada/feature13.svg",
    image: "/caseStudy/strada/featurebg3.png",
    paragraph:
      "Provides a calendar-based tool for tracking budgetary requirements and monitoring expenses.",
  },
  {
    id: 4,
    feature: "COI (PDF Data Extraction)",
    icon: "/caseStudy/strada/feature4.svg",
    icon2: "/caseStudy/strada/feature14.svg",
    image: "/caseStudy/strada/featurebg4.png",
    paragraph:
      "Allows for automatic extraction of data from Certificate of Insurance (COI) documents in PDF format, reducing the need for manual data entry.",
  },
]

export const resultsList = [
  {
    id: 1,
    heading: "Increased Business Growth",
    description: `Client realized benefits in the form of business growth and increased user base`,
    icon: "/caseStudy/strada/result5.svg",
  },
  {
    id: 2,
    heading: "Improved User Experience",
    description: `Outcomes included an elevated user experience and increased efficiency, as evidenced by improved code climate grade`,
    icon: "/caseStudy/strada/result2.svg",
  },
  {
    id: 3,
    heading: "Simplified Property Records Maintenance",
    description: `A streamlined process with no more manual labor on excel sheets`,
    icon: "/caseStudy/strada/result3.svg",
  },
  {
    id: 4,
    heading: "Improved Task Management",
    description: `The platform ensures timely completion of necessary tasks, reducing the time and effort required for maintenance.`,
    icon: "/caseStudy/strada/result4.svg",
  },
  {
    id: 5,
    heading: "Code Optimization",
    description: `The aim of the project was to optimize the functionality of the code and enhance client satisfaction`,
    icon: "/caseStudy/strada/result1.svg",
  },
]

export const businessNeedsList = [
  {
    id: 1,
    heading: "Simplified Property management",
    description: "",
    icon: "/caseStudy/strada/business1.svg",
  },
  {
    id: 2,
    heading: "Streamlined Payment Process",
    description: "",
    icon: "/caseStudy/strada/business2.svg",
  },
  {
    id: 3,
    heading: "Enhanced Search Functionality",
    description: "",
    icon: "/caseStudy/strada/business3.svg",
  },
  {
    id: 4,
    heading: "Enhanced data accuracy Via management systems",
    description: "",
    icon: "/caseStudy/strada/business4.svg",
  },
  {
    id: 5,
    heading: "Streamlined Payment Process",
    description: "",
    icon: "/caseStudy/strada/business5.svg",
  },
]
