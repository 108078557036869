import React, { useState, useEffect } from "react"
import Hero from "../CaseStudy/Hero"
import AboutProject from "../CaseStudy/AboutProject"
import Features from "../CaseStudy/Features"
import TechStack from "../CaseStudy/TechStack"
import AppDisplayImage from "../CaseStudy/AppDisplayImage"
import KeyChallengaes from "../CaseStudy/KeyChallanges"
import Results from "../CaseStudy/Results"
import SolutionSection from "../CaseStudy/SolutionSection"
import BusinessNeeds from "../CaseStudy/BusinessNeeds"
import ContactUs from "../CaseStudy/Features/ContactUs"
import ReviewSection from "../CaseStudy/ReviewSection"
import {
  techTags,
  aboutSections,
  techImages,
  aboutProjectIcons,
  keyChallenges,
  solutions,
  featuresList,
  resultsList,
  businessNeedsList,
} from "./data"

function Strada() {
  const [active, setActive] = useState(featuresList[0])
  const [showFeature, setShowFeature] = useState(false)

  useEffect(() => {
    setShowFeature(true)
  }, [])

  const clickHandler = feature => {
    setActive(feature)
  }
  return (
    <div>
      <Hero
        techTags={techTags}
        aboutSections={aboutSections}
        title="Streamlining Property Management with AI"
        imageUrl="/caseStudy/strada/heroBg.png"
        logoUrl="/caseStudy/strada/heroLogo.svg"
      />
      <AboutProject
        text="An advanced AI-powered task management and automation tool that empowers building managers to efficiently organize and manage their assets. The tool aims to increase productivity and optimize resource utilization for clients, thereby providing smart and efficient building management solutions."
        data={aboutProjectIcons}
      />
      <AppDisplayImage url="/caseStudy/strada/belowAbout.png" />
      <KeyChallengaes
        sideImage={"/caseStudy/strada/keyChallenges.png"}
        data={keyChallenges}
        showFullRightImage
        name="strada"
      />
      <AppDisplayImage url="/caseStudy/strada/belowKeyChallenges.png" />
      <SolutionSection data={solutions} />
      <AppDisplayImage url="/caseStudy/strada/belowSolution.png" />
      {showFeature && (
        <Features
          data={featuresList}
          onClickHandler={clickHandler}
          active={active}
        />
      )}
      <Results data={resultsList} />
      <TechStack data={techImages} page="container-2" />
      <BusinessNeeds data={businessNeedsList} />
      <ReviewSection
        data={{
          review: `"The quality of their work, their attention towards our challenges, and the project goals have been impressive"`,
          clientName: "Serge",
          clientInfo: "CEO",
        }}
      />
      <ContactUs className="no-padding" />
    </div>
  )
}

export default Strada
